import auth from './auth'
import dictionary from "./dictionary";
import admin from "./admin";
import cabinet from "./cabinet"

const Api = {
  ...auth,
  ...dictionary,
  ...admin,
  ...cabinet
}

export default Api;
