export default [
  {
    icon: '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M10.385 7.07876C10.3443 7.03726 10.2959 7.00427 10.2424 6.98169C10.1889 6.95912 10.1315 6.94742 10.0734 6.94727C10.0153 6.94712 9.95784 6.95853 9.90423 6.98083C9.85063 7.00313 9.802 7.03588 9.76118 7.07716C9.72036 7.11845 9.68816 7.16744 9.66647 7.2213C9.64478 7.27515 9.63402 7.33278 9.63483 7.39083C9.63563 7.44889 9.64798 7.5062 9.67116 7.55943C9.69434 7.61266 9.72788 7.66075 9.76983 7.70088C10.141 8.06613 10.4355 8.50183 10.636 8.98242C10.8365 9.46302 10.939 9.97882 10.9375 10.4996C10.9375 11.0346 9.40365 11.8121 7.00002 11.8121C4.5964 11.8121 3.06252 11.0342 3.06252 10.4987C3.06103 9.9815 3.16215 9.46914 3.36004 8.9913C3.55792 8.51345 3.84863 8.0796 4.21533 7.71488C4.25712 7.67452 4.29045 7.62625 4.31338 7.57287C4.33631 7.51949 4.34838 7.46209 4.34888 7.404C4.34939 7.3459 4.33832 7.2883 4.31632 7.23453C4.29432 7.18076 4.26183 7.13191 4.22076 7.09083C4.17968 7.04976 4.13083 7.01727 4.07706 6.99527C4.0233 6.97328 3.96569 6.96221 3.90759 6.96271C3.8495 6.96322 3.7921 6.97528 3.73872 6.99821C3.68534 7.02114 3.63707 7.05447 3.59671 7.09626C3.14843 7.54216 2.79305 8.07255 2.55116 8.65674C2.30927 9.24092 2.18567 9.86729 2.18752 10.4996C2.18752 11.9206 4.66683 12.6871 7.00002 12.6871C9.33321 12.6871 11.8125 11.9206 11.8125 10.4996C11.8145 9.86303 11.6892 9.23252 11.444 8.64507C11.1989 8.05763 10.8388 7.52509 10.385 7.07876Z" fill="black"/>\n' +
      '<path d="M7 7.4375C7.60571 7.4375 8.19781 7.25789 8.70143 6.92138C9.20506 6.58487 9.59759 6.10657 9.82938 5.54697C10.0612 4.98737 10.1218 4.3716 10.0037 3.77754C9.88549 3.18347 9.59381 2.63779 9.16551 2.20949C8.73722 1.78119 8.19153 1.48951 7.59746 1.37135C7.0034 1.25318 6.38763 1.31383 5.82803 1.54562C5.26843 1.77741 4.79014 2.16994 4.45362 2.67357C4.11711 3.17719 3.9375 3.7693 3.9375 4.375C3.93843 5.18694 4.26138 5.96536 4.83551 6.53949C5.40964 7.11362 6.18806 7.43657 7 7.4375ZM7 2.1875C7.43265 2.1875 7.85558 2.3158 8.21531 2.55616C8.57504 2.79653 8.85542 3.13817 9.02099 3.53788C9.18655 3.93759 9.22987 4.37743 9.14547 4.80176C9.06106 5.22609 8.85272 5.61587 8.5468 5.9218C8.24087 6.22772 7.85109 6.43606 7.42676 6.52047C7.00243 6.60487 6.56259 6.56155 6.16288 6.39599C5.76317 6.23042 5.42153 5.95004 5.18116 5.59031C4.94079 5.23058 4.8125 4.80765 4.8125 4.375C4.81319 3.79505 5.04389 3.23906 5.45397 2.82897C5.86406 2.41889 6.42005 2.1882 7 2.1875Z" fill="black"/>\n' +
      '</svg>\n',
    name: 'Профиль',
    link: 'settings',
  },
  // {
  //   icon: '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  //     '<g clip-path="url(#clip0_1058_8695)">\n' +
  //     '<mask id="mask0_1058_8695" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">\n' +
  //     '<path d="M13.6 13.6V0.400041H0.4V13.6H13.6Z" fill="white" stroke="white" stroke-width="0.8"/>\n' +
  //     '</mask>\n' +
  //     '<g mask="url(#mask0_1058_8695)">\n' +
  //     '<path d="M12.8331 6.9999C12.8331 10.2216 10.2215 12.8333 6.99982 12.8333C3.77816 12.8333 1.1665 10.2216 1.1665 6.9999C1.1665 3.77822 3.77816 1.16654 6.99982 1.16654C10.2215 1.16654 12.8331 3.77822 12.8331 6.9999Z" stroke="black" stroke-width="0.8" stroke-miterlimit="10" stroke-linejoin="round"/>\n' +
  //     '<path d="M12.8331 6.9999C12.8331 10.2216 10.2215 12.8333 6.99982 12.8333C3.77816 12.8333 1.1665 10.2216 1.1665 6.9999C1.1665 3.77822 3.77816 1.16654 6.99982 1.16654C10.2215 1.16654 12.8331 3.77822 12.8331 6.9999Z" stroke="black" stroke-width="0.8" stroke-miterlimit="10" stroke-linejoin="round"/>\n' +
  //     '</g>\n' +
  //     '<path d="M6.5 10.5001C6.5 10.7762 6.72386 11.0001 7 11.0001C7.27614 11.0001 7.5 10.7762 7.5 10.5001H6.5ZM6.5 6.41675V10.5001H7.5V6.41675H6.5Z" fill="black"/>\n' +
  //     '<path d="M9.3335 6.41675L4.66683 6.41675" stroke="black" stroke-linecap="round"/>\n' +
  //     '<path d="M9.3335 4.66675L4.66683 4.66675" stroke="black" stroke-linecap="round"/>\n' +
  //     '</g>\n' +
  //     '<defs>\n' +
  //     '<clipPath id="clip0_1058_8695">\n' +
  //     '<rect width="14" height="14" fill="white"/>\n' +
  //     '</clipPath>\n' +
  //     '</defs>\n' +
  //     '</svg>\n',
  //   name: 'Бонусы:',
  //   price: '27 053 тг',
  //   link: '',
  // },
  // {
  //   icon: '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  //     '<g clip-path="url(#clip0_1058_8727)">\n' +
  //     '<path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" fill="#F0F0F0"/>\n' +
  //     '<path d="M12.6268 9.08698C12.868 8.43698 13 7.73395 13 7.00003C13 6.26611 12.868 5.56308 12.6268 4.91309H1.3732C1.13202 5.56308 1 6.26611 1 7.00003C1 7.73395 1.13202 8.43698 1.3732 9.08698L7 9.60872L12.6268 9.08698Z" fill="#0052B4"/>\n' +
  //     '<path d="M6.99985 13C9.57964 13 11.7789 11.3717 12.6267 9.08691H1.37305C2.2208 11.3717 4.42006 13 6.99985 13Z" fill="#D80027"/>\n' +
  //     '</g>\n' +
  //     '<rect x="0.5" y="0.5" width="13" height="13" rx="6.5" stroke="#D0D0D0"/>\n' +
  //     '<defs>\n' +
  //     '<clipPath id="clip0_1058_8727">\n' +
  //     '<rect x="1" y="1" width="12" height="12" rx="6" fill="white"/>\n' +
  //     '</clipPath>\n' +
  //     '</defs>\n' +
  //     '</svg>\n',
  //   name: 'Cменить язык',
  //   link: 'settings',
  // },
  {
    icon: '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M5.82117 9.08152C5.96631 9.08152 6.10551 9.13918 6.20814 9.24181C6.31078 9.34444 6.36843 9.48364 6.36843 9.62879V10.9621C6.36861 11.167 6.45008 11.3635 6.59496 11.5084C6.73984 11.6533 6.9363 11.7348 7.14122 11.7351H10.9662C11.1711 11.7348 11.3675 11.6533 11.5124 11.5084C11.6573 11.3635 11.7388 11.167 11.739 10.9621V3.02942C11.7388 2.8245 11.6573 2.62802 11.5124 2.48309C11.3675 2.33817 11.1711 2.25664 10.9662 2.2564H7.14122C6.9363 2.25664 6.73984 2.33817 6.59496 2.48309C6.45008 2.62802 6.36861 2.8245 6.36843 3.02942V4.18187C6.36843 4.32702 6.31078 4.46622 6.20814 4.56885C6.10551 4.67148 5.96631 4.72914 5.82117 4.72914C5.67602 4.72914 5.53682 4.67148 5.43419 4.56885C5.33156 4.46622 5.2739 4.32702 5.2739 4.18187V3.02942C5.2745 2.53434 5.47142 2.0597 5.82148 1.70961C6.17153 1.35951 6.64614 1.16253 7.14122 1.16187H10.9662C11.4613 1.16253 11.9359 1.35951 12.2859 1.70961C12.636 2.0597 12.8329 2.53434 12.8335 3.02942V10.9621C12.8329 11.4572 12.636 11.9318 12.2859 12.2819C11.9359 12.632 11.4613 12.829 10.9662 12.8296H7.14122C6.64614 12.829 6.17153 12.632 5.82148 12.2819C5.47142 11.9318 5.2745 11.4572 5.2739 10.9621V9.62879C5.2739 9.48364 5.33156 9.34444 5.43419 9.24181C5.53682 9.13918 5.67602 9.08152 5.82117 9.08152Z" fill="#F94848"/>\n' +
      '<path d="M1.32707 6.59719L3.27466 4.65096C3.37598 4.54968 3.51296 4.49216 3.65622 4.49075C3.79947 4.48934 3.93756 4.54416 4.04084 4.64344C4.26203 4.85619 4.25678 5.21647 4.0397 5.43356L3.02566 6.44828H7.76865C7.91379 6.44828 8.05299 6.50594 8.15563 6.60857C8.25826 6.71121 8.31592 6.85041 8.31592 6.99555C8.31592 7.1407 8.25826 7.2799 8.15563 7.38253C8.05299 7.48516 7.91379 7.54282 7.76865 7.54282H3.02566L4.04951 8.56667C4.15216 8.6693 4.20985 8.8085 4.20987 8.95367C4.20989 9.09883 4.15225 9.23805 4.04962 9.34071C3.94699 9.44337 3.80778 9.50106 3.66262 9.50108C3.51746 9.5011 3.37824 9.44345 3.27558 9.34082L1.32707 7.39323C1.27474 7.341 1.23321 7.27896 1.20488 7.21066C1.17655 7.14237 1.16197 7.06915 1.16197 6.99521C1.16197 6.92127 1.17655 6.84805 1.20488 6.77976C1.23321 6.71146 1.27474 6.64942 1.32707 6.59719Z" fill="#F94848"/>\n' +
      '</svg>\n',
    name: 'Выход',
    link: 'logout',
  },
]
