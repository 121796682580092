import http from "./interceptor";

const clientCourse = {
  getList: () => http.get('/courses/my'),
  getCourseById: (id) => http.get(`/courses/my/${id}`),
}

const clientLesson = {
  getList: () => http.get('/courses/my'),
  getLessonById: (id) => http.get(`/courses/my/${id}`),
}

const clientLessonPage = {
  getList: (courseId, lessonId, params) => http.get(`/courses/${courseId}/lessons/${lessonId}/pages`, {params}),
}

const clientTask = {
  getTaskById: (courseId, lessonId, taskId) => http.get(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}`,),
  sendSingleTestAnswer: (id, params) => http.get(`tasks/${id}/give-test-answer`, {params}),
  sendShortTextAnswer: (id, params) => http.get(`tasks/${id}/give-question-answer`, {params}),
  result: (id) => http.get(`/tasks/${id}/result`)
}

export default {
  clientCourse,
  clientLesson,
  clientLessonPage,
  clientTask,
}
