<template>
  <div class="delete-modal-form">
    <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M72.9289 0.833499H37.0705C35.641 0.832367 34.2254 1.11417 32.9053 1.66267C31.5852 2.21116 30.3867 3.0155 29.3788 4.02933L4.02884 29.3793C3.01502 30.3871 2.21067 31.5857 1.66218 32.9058C1.11368 34.2259 0.831878 35.6415 0.833011 37.071V72.9293C0.831878 74.3589 1.11368 75.7745 1.66218 77.0946C2.21067 78.4147 3.01502 79.6132 4.02884 80.621L29.3788 105.971C30.3867 106.985 31.5852 107.789 32.9053 108.338C34.2254 108.886 35.641 109.168 37.0705 109.167H72.9289C74.3584 109.168 75.774 108.886 77.0941 108.338C78.4142 107.789 79.6127 106.985 80.6205 105.971L105.971 80.621C106.984 79.6132 107.789 78.4147 108.337 77.0946C108.886 75.7745 109.167 74.3589 109.166 72.9293V37.071C109.167 35.6415 108.886 34.2259 108.337 32.9058C107.789 31.5857 106.984 30.3871 105.971 29.3793L80.6205 4.02933C79.6127 3.0155 78.4142 2.21116 77.0941 1.66267C75.774 1.11417 74.3584 0.832367 72.9289 0.833499Z" fill="#F94848"/>
      <path d="M59.0619 61.7712V23.8545C59.0619 22.7771 58.6338 21.7437 57.872 20.9819C57.1101 20.22 56.0768 19.792 54.9994 19.792C53.9219 19.792 52.8886 20.22 52.1267 20.9819C51.3649 21.7437 50.9369 22.7771 50.9369 23.8545V61.7712C50.9369 62.8486 51.3649 63.8819 52.1267 64.6438C52.8886 65.4056 53.9219 65.8337 54.9994 65.8337C56.0768 65.8337 57.1101 65.4056 57.872 64.6438C58.6338 63.8819 59.0619 62.8486 59.0619 61.7712ZM54.9994 90.2087C53.6602 90.2087 52.3511 89.8116 51.2377 89.0676C50.1242 88.3236 49.2564 87.2661 48.7439 86.0289C48.2315 84.7917 48.0974 83.4303 48.3586 82.1169C48.6199 80.8035 49.2647 79.597 50.2117 78.6501C51.1586 77.7032 52.365 77.0584 53.6784 76.7971C54.9918 76.5358 56.3532 76.6699 57.5904 77.1824C58.8276 77.6949 59.8851 78.5627 60.6291 79.6762C61.3731 80.7896 61.7702 82.0987 61.7702 83.4378C61.7702 85.2336 61.0568 86.9558 59.7871 88.2255C58.5173 89.4953 56.7951 90.2087 54.9994 90.2087Z" fill="#EDEBEA"/>
    </svg>
    <p class="delete-modal-title">Вы уверены?</p>
    <p class="delete-modal-subtitle">Данные полностью  удалятся и восстановить их уже не получится</p>


    <v-button  @click="handleDelete" size="btn-w-100" type="btn-danger">Все равно удалить</v-button>
    <v-button size="btn-w-100" @click="handleClose" type="btn-outline-basic">Отмена</v-button>
  </div>
</template>

<script>
import VButton from '@/components/VButton';

export default {
  name: "VDeleteConfirmModal",

  components: {
    VButton
  },

  props: {
    id: Number,
    cancel: {
      type: Function,
      default: () => {}
    },
    delete: {
      type: Function,
      default: () => {}
    }
  },

  mounted() {
  },

  methods: {
    handleDelete() {
      this.delete(this.id)
    },
    handleClose() {
      this.vGlModal.hide('v-delete-confirm-modal');
    }
  }
}
</script>

<style scoped>

</style>
