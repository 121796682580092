<template>
  <div class="search-wrapper">
    <div class="search-form">
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.6875 31.2047L24.1673 22.8208C26.3985 20.3967 27.7694 17.1908 27.7694 13.6631C27.7683 6.11671 21.5524 0 13.8842 0C6.21589 0 0 6.11671 0 13.6631C0 21.2096 6.21589 27.3263 13.8842 27.3263C17.1974 27.3263 20.2362 26.1803 22.6232 24.2751L31.1764 32.6921C31.5932 33.1026 32.2698 33.1026 32.6866 32.6921C32.7857 32.5953 32.8645 32.4796 32.9183 32.352C32.9721 32.2243 32.9999 32.0871 33 31.9486C33.0001 31.81 32.9725 31.6729 32.9188 31.5451C32.8652 31.4174 32.7865 31.3016 32.6875 31.2047ZM13.8842 25.2241C7.39597 25.2241 2.13627 20.0481 2.13627 13.6631C2.13627 7.27817 7.39597 2.10215 13.8842 2.10215C20.3724 2.10215 25.632 7.27817 25.632 13.6631C25.632 20.0481 20.3724 25.2241 13.8842 25.2241Z" fill="#B3B3B3"/>
      </svg>
      <input type="text" placeholder="Искать на сайте" v-model="search">
      <v-button size="btn-md" @click="handleSearch">Найти</v-button>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/VButton'
import { useCommonStore  } from '@/stores/common'

export default {
  name: "VSearch",

  setup() {
    const commonStore = useCommonStore()
    return {
      commonStore,
    }
  },

  components: {
    VButton
  },

  data() {
    return {
      search: ''
    }
  },

  methods: {
    handleSearch() {
      console.log(this.search)
    }
  }
}
</script>

<style scoped>

</style>
